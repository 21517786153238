import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/material/Menu';
import { createTheme } from "@material-ui/core/styles"
import AppsIcon from '@mui/icons-material/Apps';
import CustomizedMenus from './menu'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import AddRoadIcon from '@mui/icons-material/AddRoad';

const theme = createTheme();

const useStyles = makeStyles({
    btn: {
      marginRight: '10px',
      backgroundColor:"red",

      color: 'red',
      height:'100px',
      position:"fixed"
    },
    title: {
      flexGrow: 1,
    },
    bg:{
      backgroundColor:"#0F4C81",
      color:"white",
      height:'100%'

    },

    dis:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between'
      
    },
    links:{
   display:'flex',
   flexDirection:'row',
   alignContent:'space-between',
      backgroundColor:'clear'
    },
    logo:{
      fontSize:'30px'
    },
    starter:{
      fontSize:'80%',
      fontFamily: 'arial'
    }

  });


const Navbar = () => {
   const classes = useStyles();
  return (
    <AppBar position="static" className={classes.bg} >
      <Toolbar className={classes.dis}>
      <div className={classes.logo}>
      
      CPC
      <AutorenewIcon 
      sx={{fontSize:40}}
       />
      </div>
      <div>
      <EditRoadIcon />
      <p className={classes.starter}>
      Inspection tracker
      </p>
      </div>
      <div>
      <AddRoadIcon />
      <p className={classes.starter}>
      Start a ticket
      </p>
      </div>
      
      
   
      <CustomizedMenus />
    
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
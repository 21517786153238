// import ReactDom from 'react-dom';
import Button from  '@mui/material/Button'
import CustomizedMenus from '../components/navbar/menu'



const Header = ()=>{
    return (
        <div>
        <CustomizedMenus />
        <Button variant='contained'> hello there</Button>
        </div>
    )
}
export default Header
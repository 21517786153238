import logo from './logo.svg';
import './App.css';
import Header from './components/header'
import Navbar from './components/navbar/navbar'
import Footer from './components/footer/Footer'

function App() {
  return (
    <div className="App">
    <Navbar />
    <header className="App-header">
    <div className='cpcDiv'>Cell Phone Cycle</div>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          coming soon
        </p>
       
       
        <a
          className="App-link"
          href="https://tryphonegeeks.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          For now, Sell your electronics responsibly at any Phone Geeks Location
        </a>
      </header>
      <Footer />
    </div>
  );
}

export default App;

import React from 'react'
import './Footer.css'

 const Footer =()=>{
    return (
        <div className='footerDiv'>
        <div className='resDive'>
        All Rights Reserved cell phone cycle
        </div>
        <div className='cpcDiv'>
        CELL PHONE CYCLE 
        <p>St.louis</p>
        </div>
        <div> contact us : client@tryphonegeeks.com</div>
        
        </div>
    )
};

export default Footer